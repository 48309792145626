import {useRef} from 'react';

import {ReasonCards} from '@/components/common/reason-cards-v2/ReasonCards';
import {Section, SECTION_STYLES} from '@/components/common/section/Section';
import {Title} from '@/components/common/title/Title';

import {data} from './const';
import styles from './ServicesCommunication.module.css';

type ServicesCommunicationProps = {
	id?: string;
};

export function ServicesCommunication({id}: ServicesCommunicationProps) {
	const titleRef = useRef();
	return (
		<Section
			style={SECTION_STYLES.WITH_BREAKPOINTS}
			className={styles.root}
			sectionId={id}
			isFullWidth={true}
			classNameFW={styles.rootBg}
		>
			<Title with1680={false} className={styles.title} ref={titleRef}>
				Преимущества коммуникационных
				<br />
				сервисов от&nbsp;VK Tech
			</Title>
			<ReasonCards list={data} />
		</Section>
	);
}
