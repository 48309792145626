import classNames from 'classnames';

import {BUTTON_STYLES} from '@/components/base/button/Button';
import {Link} from '@/components/base/link/Link';
import DoneIcon from '@/components/common/dialogs/promo-dialog/promo-business-banner/assets/done.svg';
import Image from '@/components/common/image/Image';
import {Section, SECTION_STYLES} from '@/components/common/section/Section';
import {TryButton} from '@/components/common/try-button/TryButton';
import {LINKS} from '@/constants';
import {Media, MediaContextProvider} from '@/media';

import {Targets} from '../../../../../types/common';
import desktopImg from './assets/img.webp';
import mobileImg from './assets/img-320.webp';
import styles from './BusinessBanner.module.css';

const items = [
	<>Создайте личный кабинет на платформе VK WorkSpace</>,
	<>Пополните баланс и выберите тариф «Выгодный»</>,
	<>
		Введите промокод <span className={styles.itemBold}>BUSINESS</span> в соответствующее поле
	</>,
];

const altImg = 'Получите скидку 30% по промокоду BUSINESS';
export function BusinessBanner() {
	return (
		<Section style={SECTION_STYLES.WITH_BREAKPOINTS} className={styles.root}>
			<div>
				<div className={styles.title}>
					Получите скидку 30%
					<br />
					по промокоду <span className={styles.accent}>BUSINESS</span>
				</div>
			</div>
			<div className={styles.body}>
				<div className={styles.info}>
					<div className={styles.date}>Только до 1 октября</div>
					<TryButton
						text={'Подключить по промокоду'}
						hasDomainButtonText={'Подключить по промокоду'}
						className={classNames(styles.button)}
						style={BUTTON_STYLES.PRIMARY}
					/>
					<div className={styles.desc}>
						<div className={styles.descTitle}>Как воспользоваться промокодом:</div>
						<div className={styles.items}>
							{items.map((item, index) => {
								return (
									<div className={styles.item} key={index}>
										<DoneIcon />
										<span>{item}</span>
									</div>
								);
							})}
						</div>
						<div className={styles.disclaimer}>
							Акция действует с 02.09.24 по 01.10.24.{' '}
							<Link
								target={Targets.blank}
								href={LINKS.docsSaasTariffsBillsPromocodes}
								className={styles.link}
							>
								Подробные условия акции
							</Link>
						</div>
					</div>
				</div>

				<div className={styles.picture}>
					<div className={styles.imgWrapper}>
						<MediaContextProvider>
							<Media lessThan="desktop">
								<Image src={mobileImg} alt={altImg} />
							</Media>
							<Media greaterThanOrEqual="desktop">
								<Image src={desktopImg} alt={altImg} />
							</Media>
						</MediaContextProvider>
					</div>
				</div>
			</div>
		</Section>
	);
}
