import {useRouter} from 'next/router';
import {useEffect} from 'react';

import {usePythonSettings} from '@/hooks//use-python-settings';
import {
	successSignUpQuery,
	successSignUpQueryValue,
} from '@/utilites/modify-query/get-url-without-sign-up-query';

export function useSuccessSignUp(callback: () => void) {
	const {query, isReady} = useRouter();
	const {isAuth} = usePythonSettings();

	useEffect(() => {
		const hasNewRegistryQuery = query[successSignUpQuery] === successSignUpQueryValue;
		const isRegistered = isReady && hasNewRegistryQuery && isAuth;

		if (isRegistered) {
			callback();
		}
	}, [isReady, query, isAuth]);
}
