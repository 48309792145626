import {ReactElement, VFC} from 'react';

import {Typography, VARIANTS} from '@/components/base/typography/Typography';
import {typography} from '@/utilites/typography/typography';

import Icon1Img from './assets/icon1.svg';
import Icon2Img from './assets/icon2.svg';
import Icon3Img from './assets/icon3.svg';
import Icon4Img from './assets/icon4.svg';
import Icon5Img from './assets/icon5.svg';
import Icon6Img from './assets/icon6.svg';
import styles from './ServicesCommunication.module.css';

type ItemProps = {
	id: number;
	title: string | ReactElement;
	text: string | ReactElement;
	img: VFC<React.SVGProps<SVGSVGElement>>;
};

export const data: ItemProps[] = [
	{
		id: 0,
		title: <div className={styles.contentTitle}>Единое пространство для работы</div>,
		text: (
			<Typography variant={VARIANTS.text} className={styles.contentText}>
				Все сервисы для коммуникаций в&nbsp;одной подписке
			</Typography>
		),
		img: Icon1Img,
	},
	{
		id: 1,
		title: <div className={styles.contentTitle}>Быстрый старт</div>,
		text: (
			<Typography variant={VARIANTS.text} className={styles.contentText}>
				{typography('Простая интеграция, легкое масштабирование и подключение новых пользователей')}
			</Typography>
		),
		img: Icon2Img,
	},
	{
		id: 2,
		title: <div className={styles.contentTitle}>Стабильная работа</div>,
		text: (
			<Typography variant={VARIANTS.text} className={styles.contentText}>
				{typography('Отказоустойчивая система и соответствие политикам безопасности')}
			</Typography>
		),
		img: Icon3Img,
	},
	{
		id: 3,
		title: <div className={styles.contentTitle}>Для любых устройств</div>,
		text: (
			<Typography variant={VARIANTS.text} className={styles.contentText}>
				{typography(`Сервисы доступны с компьютера, планшета и смартфона`)}
			</Typography>
		),
		img: Icon4Img,
	},
	{
		id: 4,
		title: <div className={styles.contentTitle}>Гибкое администрирование</div>,
		text: (
			<Typography variant={VARIANTS.text} className={styles.contentText}>
				{typography(
					'Управление ролями, предоставление доступа, делегирование управления почтовыми ящиками',
				)}
			</Typography>
		),
		img: Icon5Img,
	},
	{
		id: 5,
		title: <div className={styles.contentTitle}>Интуитивный интерфейс</div>,
		text: (
			<Typography variant={VARIANTS.text} className={styles.contentText}>
				{typography(
					'Знакомое окно почты, мессенджера и облачного хранилища с интуитивно понятной навигацией',
				)}
			</Typography>
		),
		img: Icon6Img,
	},
];
