import {Link} from '@/components/base/link/Link';
import {LINKS, PAGES} from '@/constants';

import styles from './FaqSectoin.module.css';

export const questions = [
	{
		question: 'VK WorkSpace — что это?',
		answer: `Это платформа для бизнеса. Включает корпоративную почту с календарем и адресной книгой, мессенджер, видеоконференции, задачи, облачное хранилище со встроенным редактором документов. Сервисы платформы раньше разрабатывались Mail.ru Group, сейчас — технологической компанией VK.`,
	},
	{
		question: 'Облагаются ли сервисы в пакете VK WorkSpace НДС?',
		answer: `За корпоративную почту, облачное хранилище, мессенджер и другие инструменты платформы не нужно платить НДС — они входят в реестр отечественного ПО.`,
	},
	{
		question: 'Нужен ли мне сайт, чтобы подключить VK WorkSpace?',
		answer: `Вы можете выбрать домен, настроить корпоративную почту и подключить остальные сервисы — сайт создавать не нужно.`,
	},
	{
		question: 'Сервис входит в реестр российского ПО? ',
		answer: `Сервисы VK WorkSpace входят в реестр отечественного ПО и соответствуют требованиям регуляторов (информационная безопасность, On-premises инсталляция, совместимость с российскими ОС).`,
	},
	{
		question: 'Сколько стоит подключить VK WorkSpace?',
		answer: (
			<>
				Стоимость тарифа &laquo;Выгодный&raquo;&nbsp;&mdash; 159&nbsp;рублей за&nbsp;пользователя
				в&nbsp;месяц при оплате за&nbsp;год. Дополнительной платы за&nbsp;подключение нет. Подробнее
				о&nbsp;тарифах можно узнать{' '}
				<Link href={PAGES.tariffs.href} className={styles.link}>
					в документации
				</Link>
				.
			</>
		),
	},
	{
		question: 'Что делать, если возникает ошибка «Домен уже добавлен»?',
		answer: (
			<>
				Это значит, что данный домен уже существует в&nbsp;системе. Скорее всего, его добавил
				предыдущий владелец. Подтвердите права на&nbsp;владение доменом&nbsp;&mdash;
				и&nbsp;мы&nbsp;освободим его для вас. Подробнее о&nbsp;том, как это сделать,&nbsp;&mdash;
				читайте{' '}
				<Link href={LINKS.saasDocsTroubleshooting} className={styles.link}>
					в&nbsp;документации
				</Link>
				.
			</>
		),
	},
	{
		question: 'Облачные решения для малого или крупного бизнеса: какие преимущества использования?',
		answer: `Использование SaaS-решений для бизнеса — это возможность быстро подключить софт, сэкономить на установке
		и обслуживании серверного оборудования и получить стабильные и безопасные сервисы. Благодаря использованию
		облачных решений сотрудники компании получают доступ к ежедневной коммуникации и корпоративным данным, где бы
		они ни находились — дома, в офисе или в поездке.`,
	},
	{
		question: 'Что такое VK WorkSpace для бизнеса?',
		answer: `VK WorkSpace — это коммуникационная платформа для бизнеса от VK. На ней есть все инструменты для совместной
		работы команд: корпоративная почта с календарем и адресной книгой, мессенджер, видеоконференции, задачи, облачное
		хранилище со встроенным редактором документов.`,
	},
	{
		question: 'Как оплатить VK WorkSpace и какие есть способы оплаты?',
		answer: (
			<>
				VK&nbsp;WorkSpace работает по&nbsp;предоплатной системе: деньги списываются со&nbsp;счета
				в&nbsp;зависимости от&nbsp;выбранного вами тарифа и&nbsp;использования услуг.&nbsp;Постоплата
				доступна юрлицам, которые заключили с&nbsp;нами договор на&nbsp;оказание услуг определенного
				объема (не&nbsp;оферта).{' '}
				<Link href={LINKS.saasDocsTariffsPayment} className={styles.link}>
					Подробнее о&nbsp;том, как пополнить счет и&nbsp;оплатить услуги,&nbsp;&mdash; читайте
					в&nbsp;документации
				</Link>
				.
			</>
		),
	},
	{
		question: 'Платформу VK WorkSpace для бизнеса предоставляет ВКонтакте?',
		answer: `Нет, сервисы платформы раньше разрабатывались Mail.ru Group, сейчас — технологической компанией VK, которая объединяет различные решения для людей и бизнеса. В VK входит социальная сеть ВКонтакте.`,
	},
	{
		question: 'Как производится оплата?',
		answer: 'Вы можете оплатить сервис картой прямо в личном кабинете. Если вам нужны закрывающие документы для бухгалтерии, можно оплатить по счету — также через личный кабинет.',
	},
	{
		question: 'Есть ли приложение для календаря?',
		answer: 'Отдельного приложения для календаря нет. Календарь доступен для всех пользователей почты VK WorkMail в web-версии, десктопной версии, мобильном приложении.',
	},
	{
		question:
			'Хотим подключить почту для домена, тариф "Выгодный" на 100 сотрудников, нам нужно перенести данные с Яндекс 360 на ваш сервис. Как это можно сделать? Какие способы оплаты сервиса возможны?',
		answer: (
			<>
				Вы можете перенести данные со своей почты самостоятельно. Для этого мы подготовили инструкцию
				по переезду с других сервисов&nbsp;
				<a href={LINKS.helpDomainMigration} target="_blank" rel="noreferrer">
					{LINKS.helpDomainMigration}
				</a>
				<br />
				Вы можете оплатить сервис картой прямо в личном кабинете. Если вам нужны закрывающие документы
				для бухгалтерии, можно оплатить по счету — также через личный кабинет.
			</>
		),
	},
	{
		question: 'Как переехать на VK WorkSpace с другой платформы?',
		answer: (
			<>
				Чтобы мигрировать с&nbsp;других сервисов на&nbsp;VK&nbsp;WorkSpace, заполните контактную форму
				внизу этой страницы и&nbsp;оставьте заявку на&nbsp;подключение. Мы&nbsp;свяжемся с&nbsp;вами
				и&nbsp;обсудим детали. Вы&nbsp;также можете самостоятельно перенести данные с&nbsp;других
				сервисов&nbsp;&mdash; подробнее об&nbsp;этом читайте{' '}
				<Link href={LINKS.saasDocsWorkMailMigration} className={styles.link}>
					в&nbsp;инструкции
				</Link>
				.
			</>
		),
	},
];
