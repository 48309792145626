import Script from 'next/script';

import {MICRO_DATA} from '@/constants';

export function SchemaJSON() {
	return (
		<Script
			id="schema-json"
			defer={true}
			strategy="afterInteractive"
			type="application/ld+json"
			dangerouslySetInnerHTML={{
				__html: JSON.stringify(MICRO_DATA),
			}}
		/>
	);
}
