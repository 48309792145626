import classNames from 'classnames';
import React, {useMemo, useState} from 'react';

import {Button} from '@/components/base/button/Button';
import {Link} from '@/components/base/link/Link';
import {Typography, VARIANTS} from '@/components/base/typography/Typography';
import Image from '@/components/common/image/Image';
import {Section, SECTION_STYLES} from '@/components/common/section/Section';
import {typography} from '@/utilites/typography/typography';

import {Targets} from '../../../../../types/common';
import LogoArrow from './assets/arrow.svg';
import styles from './Cases.module.css';
import {buttonReadAll, data} from './const';

export function Cases() {
	const [isPreview, setPreview] = useState(true);
	const onClickButton = () => setPreview(false);

	const visibleCases = useMemo(() => {
		return isPreview ? data.slice(0, 3) : data;
	}, [isPreview]);

	return (
		<Section
			isFullWidth
			style={SECTION_STYLES.WITH_BREAKPOINTS}
			direction="column"
			className={styles.section}
			classNameFW={styles.rootBg}
		>
			<Typography variant={VARIANTS.h1} className={classNames(styles.mainTitle)}>
				Истории успеха наших клиентов
			</Typography>
			<div className={classNames(styles.cards, {[styles.preview]: isPreview})}>
				{visibleCases.map(({logo, description, link}, i) => {
					return (
						<div key={i} className={styles.card}>
							<div className={styles.logoWrapper}>
								<Image src={logo} alt="logo" layout="responsive" objectFit="contain" />
							</div>
							<div className={styles.desc}>{typography(description)}</div>
							<div className={styles.actions}>
								<Link className={styles.link} target={Targets.blank} href={link}>
									<Typography className={styles.text} variant={VARIANTS.text}>
										Читать
										<LogoArrow className={styles.textLogo} />
									</Typography>
								</Link>
							</div>
						</div>
					);
				})}
			</div>
			{isPreview ? (
				<Button className={classNames(styles.button, styles.moreStories)} onClick={onClickButton}>
					Больше историй
				</Button>
			) : (
				<Button as="a" href={buttonReadAll.link} className={styles.button} target={Targets.blank}>
					{buttonReadAll.text}
				</Button>
			)}
		</Section>
	);
}
