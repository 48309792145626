export const vkWsSwiperBreakpoints = {
	400: {
		slidesPerView: 1,
	},
	640: {
		slidesPerView: 2,
	},
	768: {
		slidesPerView: 3,
	},
};

export const ANCHORS = {
	MAIN: 'main',
	TRIGGERS: 'triggers',
	ABOUT_SERVICES: 'aboutservices',
	FOR_BUSINESS: 'forbusiness',
	OUR_EXCELLENCE: 'ourexcellence',
	CASES: 'cases',
	REQUEST: 'request',
};
