import cn from 'classnames';

import {BUTTON_STYLES} from '@/components/base/button/Button';
import {TryButton} from '@/components/common/try-button/TryButton';

import IconOnPremises from './assets/on-premises.svg';
import IconSaas from './assets/saas.svg';
import {Variant} from './interfaces';
import {RequestButton} from './RequestButton';
import styles from './Variants.module.css';
export const data: Variant[] = [
	{
		title: (
			<>
				Размещение
				<br />
				в&nbsp;облаке
			</>
		),
		subtitle: <>Сервисы для&nbsp;совместной работы по&nbsp;модели SaaS</>,
		desc: [
			<>Прозрачные тарифы и&nbsp;расширенные возможности для&nbsp;крупных клиентов</>,
			<>Хранение данных в&nbsp;российском облаке и&nbsp;соблюдение ФЗ-152</>,
			<>Безопасность данных на&nbsp;уровне приложения</>,
			<>Экономия на&nbsp;серверном оборудовании и&nbsp;содержании ИТ-специалистов</>,
		],
		Icon: IconSaas,
		button: (
			<TryButton text="Подключить в облаке" style={BUTTON_STYLES.PRIMARY} className={styles.button} />
		),
	},
	{
		title: (
			<>
				Размещение
				<br />
				на&nbsp;серверах компании
			</>
		),
		subtitle: <>Сервисы для&nbsp;совместной работы по&nbsp;модели On-premises</>,
		desc: [
			<>Хранение данных внутри контура компании</>,
			<>Стабильная работа при высоких нагрузках</>,
			<>Синхронизация с Exchange на этапе миграции</>,
			<>Интеграция с&nbsp;корпоративными сервисами и&nbsp;системами безопасности</>,
		],
		Icon: IconOnPremises,
		button: <RequestButton text="Оставить заявку" className={cn(styles.button, styles.whiteButton)} />,
	},
];
